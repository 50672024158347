import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Seo from '../../components/seo'
import BlogHeader from '../../components/blogheader'
import BlogTags from '../../components/blogtags'


const BlogPost = ({ data }) => {
    const title = `Logickal Blog: ${data.mdx.frontmatter.title}`
    return (
        <div>
            <Seo title={title}/>
            <BlogHeader/>
            <div className='container mx-auto '>
                <h3>{data.mdx.frontmatter.title}</h3>
                <p className="italic">Posted {data.mdx.frontmatter.date} by Logickal</p>
                <div>Categories: <BlogTags tags={data.mdx.frontmatter.tags}/></div>

                <div className="body mx-20 my-10 px-25">
                    <MDXRenderer>
                        {data.mdx.body}
                    </MDXRenderer>
                </div>
             </div>

        </div>
    )
}

export const query = graphql`
    query PostQuery($id: String) {
        mdx(id: {eq: $id}) {
        frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            tags
            }
        body
        }
    }
    
`

//7f8c7e00-615c-5fa3-8cb4-896316d09765

export default BlogPost